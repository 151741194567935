export const COLORS = {
  white: "hsl(0deg, 0%, 100%)",
  gray: {
    100: "hsl(185deg, 5%, 95%)",
    300: "hsl(190deg, 5%, 80%)",
    500: "hsl(196deg, 4%, 60%)",
    700: "hsl(220deg, 5%, 40%)",
    900: "hsl(220deg, 3%, 20%)",
  },
  primary: "hsl(340deg, 65%, 47%)",
  secondary: "hsl(240deg, 60%, 63%)",
};

export const WEIGHTS = {
  normal: 500,
  medium: 600,
  bold: 800,
};

export const numBodies = 7;
export const numHeads = 12;
export const numFaces = 9;
export const numAccessories = 5;

export const skinColors = [
  { label: "Dark Espresso", color: "hsl(26deg, 48%, 28%)" },
  { label: "Espresso", color: "hsl(27deg, 55%, 34%)" },
  { label: "Chestnut", color: "hsl(28deg, 50%, 45%)" },
  { label: "Almond", color: "hsl(28deg, 47%, 51%)" },
  { label: "Golden", color: "hsl(34deg, 52%, 54%)" },
  { label: "Honey", color: "hsl(31deg, 57%, 58%)" },
  { label: "Warm Beige", color: "hsl(33deg, 59%, 68%)" },
  { label: "Sand", color: "hsl(33deg, 51%, 71%)" },
  { label: "Warm Ivory", color: "hsl(32deg, 79%, 76%)" },
  { label: "Ivory", color: "hsl(33deg, 69%, 80%)" },
  { label: "Porcelain", color: "hsl(26deg, 72%, 85%)" },
];

export const clothesColors = [
  { label: "Pale tomato", color: "hsl(5deg, 70%, 70%)" },
  { label: "Goldenrod", color: "hsl(40deg, 100%, 75%)" },
  { label: "Sunshine", color: "hsl(52deg, 95%, 70%)" },
  { label: "Grass", color: "hsl(125deg, 65%, 70%)" },
  { label: "Teal", color: "hsl(180deg, 45%, 75%)" },
  { label: "Cobalt", color: "hsl(220deg, 55%, 70%)" },
  { label: "Fuscia", color: "hsl(315deg, 65%, 70%)" },
  { label: "Pink", color: "hsl(345deg, 75%, 70%)" },
  { label: "Dark Gray", color: "hsl(180deg, 9%, 19%)" },
  { label: "Gray", color: "hsl(180deg, 3%, 50%)" },
  { label: "White", color: "hsl(180deg, 10%, 85%)" },
];

export const defaultSkinColor = skinColors[6].color;
export const defaultClothesColor = clothesColors[0].color;
