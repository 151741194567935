import React from "react";

import { defaultSkinColor, defaultClothesColor } from "../../constants";
import Character from "../Character";
import MaxWidthWrapper from "../MaxWidthWrapper";
import Faq from "../Faq";
import MoojinBox from "../MoojinBox";
import styled from "styled-components/macro";
import { COLORS } from "../../constants";

import styles from "./LandingPage.module.css";

function LandingPage() {
  const [body, head, face, accessory] = [0, 0, 0, 0];
  const [skinColor] = React.useState(defaultSkinColor);
  const [clothesColor] = React.useState(defaultClothesColor);

  const pointTextColor = COLORS.secondary;

  return (
    <main className={styles.characterEditor}>
      <div className={styles.perspectiveEffect}></div>
      <MaxWidthWrapper className={styles.maxWidthWrapper}>
        <BubbleTextRight>함께 애쓰고, 도와주고, 회고하는</BubbleTextRight>
        <IntroWrapper id="intro">
          <Intro>지금 내게 어려운</Intro>
          <Intro>
            <PointText color={pointTextColor}>단 한 가지</PointText>를
          </Intro>
          <Intro>
            <PointText color={pointTextColor}>해내는</PointText> 모임
          </Intro>
        </IntroWrapper>

        <BubbleTextLeft>올해의 터닝 포인트</BubbleTextLeft>

        <Paragraph>
          <Sentence>
            ‘살아있다’와 '살고 있다' 라는 말의 차이를 아시나요?
          </Sentence>
          <Sentence>
            단순히 살아있는 사람과 원하는 바를{" "}
            <PointText color={pointTextColor}>해내기 위해</PointText> 살고 있는
            사람은 다릅니다.
          </Sentence>
          <Sentence>
            <PointText color={pointTextColor}>무진장</PointText>은 해내는
            사람들이 모인 곳입니다.
          </Sentence>
        </Paragraph>

        <Paragraph>
          <Sentence>올해 해내고 싶은 어려운 일이 있나요?</Sentence>
          <Sentence>
            그럼 <PointText color={pointTextColor}>솔직하게</PointText> 그게
            지금은 내게 어렵다고 인정해봅시다.
          </Sentence>
          <Sentence>
            그리고 그 한 가지에 무진장 애써봅시다. 할 수 있는 전부를 해봅시다.
          </Sentence>
        </Paragraph>

        <Paragraph>
          <Sentence>한 해의 끝에 이렇게 말 할 수 있으면 좋겠습니다.</Sentence>

          <Sentence>남들에게도 자신에게도, 솔직하고 분명하게요.</Sentence>
          <Sentence>
            <PointText color={pointTextColor}>'해냈다'</PointText>
          </Sentence>
        </Paragraph>

        <WhatToDoWrapper id="plan">
          <BubbleTextWhatToDo>무진장에서는 무엇을 하나요?</BubbleTextWhatToDo>

          <CardBox>
            <CardColumn>
              <Card>
                <CardParagraph>
                  <MarketingPhrase> 하나, </MarketingPhrase>
                  <MarketingPhrase> 해내고 싶은 바를 정해요 </MarketingPhrase>
                  <Pad size="5px" />
                  <CardSentence>자신에게 솔직하게 물어봅시다.</CardSentence>
                  <CardSentence>구체적이고 측정 가능한 목표를요</CardSentence>
                </CardParagraph>
              </Card>
              <Card>
                <CardParagraph>
                  <MarketingPhrase> 둘, </MarketingPhrase>
                  <MarketingPhrase> 주간 회고를 작성해요 </MarketingPhrase>
                  <Pad size="5px" />
                  <CardSentence>
                    애쓰고, 배우고, 느낀 점을 기록해요
                  </CardSentence>
                  <CardSentence>
                    문제 해결을 위해 필요한 바도 생각해봅시다
                  </CardSentence>
                </CardParagraph>
              </Card>
            </CardColumn>
            <CardColumn>
              <Card>
                <CardParagraph>
                  <MarketingPhrase> 셋, </MarketingPhrase>
                  <MarketingPhrase> 매달 만나서 나눠요 </MarketingPhrase>
                  <Pad size="5px" />
                  <CardSentence>안전한 공간에서</CardSentence>
                  <CardSentence>서로를 존중하면서</CardSentence>
                  <CardSentence>맷돌처럼 진지하게요</CardSentence>
                  <Pad size="25px" />
                  <MileStoneImg src="/assets/milestone2.jpg"></MileStoneImg>
                </CardParagraph>
              </Card>
            </CardColumn>
          </CardBox>
        </WhatToDoWrapper>
        <MoojinBox />
        <Faq />
      </MaxWidthWrapper>
      <div className={styles.characterWrapper}>
        <Character
          body={body}
          head={head}
          face={face}
          accessory={accessory}
          skinColor={skinColor}
          clothesColor={clothesColor}
        />
      </div>
    </main>
  );
}

const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Intro = styled.h2`
  font-size: 5rem;
  font-weight: 1000;
  margin-top: 0;
  margin-bottom: -20px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.5px;
  word-break: keep-all;
  text-align: center;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const PointText = styled.b`
  color: ${(p) => p.color};
`;

const BubbleText = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  background-color: white;

  border-radius: 20px;
  padding: 10px;
`;

const BubbleTextRight = styled(BubbleText)`
  margin-left: auto;
  width: 320px;
`;

const BubbleTextLeft = styled(BubbleText)`
  margin-right: auto;
  width: 200px;
`;

const Paragraph = styled.div`
  margin-top: 25px;

  &:first-of-type {
    margin-top: 100px;
  }
`;

const Sentence = styled.p`
  font-size: 1.15rem;
  font-weight: 400;
`;

const WhatToDoWrapper = styled.div`
  margin-top: 180px;
`;

const BubbleTextWhatToDo = styled(BubbleText)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 295px;
  background-color: ${COLORS.secondary};
  color: white;
`;

const CardBox = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.25 1 20px;
  gap: 15px;

  &:last-of-type {
    flex: 1 1 20px;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  min-height: 150px;
  flex: 1;
`;

const CardParagraph = styled.div`
  padding: 10px;
`;

const MarketingPhrase = styled.p`
  font-size: 1.3em;
  font-weight: 600;
`;

const Pad = styled.div`
  margin-bottom: ${(p) => p.size || "px"};
`;

const CardSentence = styled.li`
  font-size: 1rem;
`;

const MileStoneImg = styled.img`
  width: 100%;
  height: 100%;
`;

export default LandingPage;
