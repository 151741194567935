import React from "react";
import styled from "styled-components/macro";

import { COLORS, WEIGHTS } from "../../constants";
import Logo from "../Logo";
import SuperHeader from "../SuperHeader";

const Header = (props) => {
  return (
    <header>
      <Wrapper>
        <SuperHeader {...props} />
        <MainHeader>
          <Side>
            <Logo />
          </Side>
          <Nav>
            <NavLink href="#">모임 소개</NavLink>
            <NavLink href="#plan">플랜</NavLink>
            <NavLink href="#faq">FAQ</NavLink>
            <NavLink href="https://root-goal-1fd.notion.site/d6756e78c7bb4b25bc4b074308c8bf17?pvs=4">
              기획 에세이
            </NavLink>
          </Nav>
          <Side />
        </MainHeader>
      </Wrapper>
    </header>
  );
};

const Wrapper = styled.div`
  position: sticky;
  top: -16px;
`;

const MainHeader = styled.div`
  border-bottom: 1px solid ${COLORS.gray[300]};
  display: flex;
  min-height: 72px;
  align-items: baseline;
  padding: 16px 32px;
`;

const Nav = styled.nav`
  display: flex;
  gap: 48px;
  margin: 0px 48px;
`;

const Side = styled.div`
  flex: 1;
`;

const NavLink = styled.a`
  font-size: 1.125rem;
  text-transform: uppercase;
  text-decoration: none;
  color: ${(p) => COLORS.gray[900]};
  font-weight: ${WEIGHTS.medium};
  text-wrap: nowrap;
`;

export default Header;
