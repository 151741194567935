import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";

import Icon from "../Icon";

const SuperHeader = (props) => {
  return (
    <Wrapper>
      <Box>
        <MarketingMessage id="header">
          해내는 사람들이 모인 곳:
        </MarketingMessage>
        <b>Season 1. 2024.9.13일 시작.</b>
      </Box>
      {props.showRedCliff && (
        <Icon
          id="heart"
          strokeWidth={1}
          color="red"
          onClick={() => props.setShowRedCliff((showRedCliff) => !showRedCliff)}
        />
      )}
      {!props.showRedCliff && (
        <Icon
          id="heart"
          strokeWidth={1}
          onClick={() => props.setShowRedCliff((showRedCliff) => !showRedCliff)}
        />
      )}
      <HelpLink href="https://www.instagram.com/moojin.io/">
        <Icon id="aperture" strokeWidth={1} />
      </HelpLink>
    </Wrapper>
  );
};

const Box = styled.div`
  display: flex;
  gap: 15px;
  margin-right: auto;
`;

const Wrapper = styled.div`
  font-size: 0.875rem;
  align-items: center;
  color: ${COLORS.gray[300]};
  background-color: ${COLORS.gray[900]};
  display: flex;
  gap: 24px;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
`;

const MarketingMessage = styled.span`
  color: ${COLORS.white};
  margin-right: auto;
`;

const HelpLink = styled.a`
  color: inherit;
  text-decoration: none;
  outline-offset: 2px;

  &:not(:focus-visible) {
    outline: none;
  }
`;

export default SuperHeader;
