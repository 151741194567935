import React from "react";
import styled from "styled-components/macro";

const MoojinBox = () => {
  return (
    <Wrapper>
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
      <Box />
    </Wrapper>
  );
};

const Box = styled.div`
  --angle: 45deg;
  --start-color: calc(var(--hue) - var(--range));
  --end-color: calc(var(--hue) + var(--range));
  --radius: 4px;
  --rotate: 5deg;

  width: 125px;
  height: 125px;
  background: linear-gradient(
    var(--angle),
    hsl(var(--start-color) 100% 50%),
    hsl(var(--end-color) 100% 50%)
  );
  border-radius: var(--radius);
  transform: rotate(var(--rotate));

  &:nth-of-type(2n) {
    --hue: 30deg;
  }
  &:nth-of-type(3n) {
    --hue: -30deg;
    --rotate: 120deg;
  }
  &:nth-of-type(9n - 3) {
    --hue: -90deg;
    --rotate: 50deg;
  }
  &:nth-of-type(3n + 1) {
    --angle: -60deg;
    --rotate: 150deg;
  }
  &:nth-of-type(2n + 1) {
    /* --range: 50deg; */
  }
  &:nth-of-type(7n - 6) {
    /* --range: 120deg; */
  }
  &:nth-of-type(14) {
    --radius: 50%;
  }
`;

const Wrapper = styled.div`
  --hue: 0deg;
  --range: 165deg;

  margin: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  flex-wrap: wrap;
  width: 600px;
`;

export default MoojinBox;
