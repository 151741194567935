import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../constants";

const Faq = () => {
  // Our site features two visual headers, but they should be
  // grouped semantically as a single header.
  return (
    <Wrapper id="faq">
      <Header>자주 묻는 질문</Header>
      <MessageSent>무진장 첫번째 시즌은 언제부터 시작나요?</MessageSent>
      <MessageReceived>
        9월 13일 시작입니다. 12월 6일 시즌 종료.
      </MessageReceived>
      <MessageSent>등록은 어떻게 하나요?</MessageSent>
      <MessageReceived>곧 등록 방법을 공지하겠습니다.</MessageReceived>
      <MessageSent>참가비가 있나요?</MessageSent>
      <MessageReceived>
        네 있습니다. <b>$200</b> 입니다.
        <br />
        <br />
        모임에 성실히 참여할 사람을 찾습니다. <br /> 성실하게 모임을 끝마치면
        <b> 참가비를 대부분 돌려드리겠습니다.</b>
      </MessageReceived>
      <MessageSent>모이는 참가비로 뭘 하나요?</MessageSent>
      <MessageReceived>
        무진장 라운지 호스트에게 수고비로 드릴 계획입니다.
      </MessageReceived>
      <MessageSent>모임에 성실하게 참여한다는 말은 무슨 뜻이죠?</MessageSent>
      <MessageReceived>
        자신이 해내고자 했던 한 가지를 결국 해낸다는 말입니다. <br></br>
        동시에 매달 모임에도 성실히 참여해야 합니다.
        <br></br>
        <br></br>이 두가지 조건을 만족하면, <b>$160</b>을 돌려드리겠습니다.
      </MessageReceived>
      <MessageSent>참가 자격이 있나요?</MessageSent>
      <MessageReceived>
        네, 있습니다. 자기가 애쓰고 싶은 문제에 맷돌처럼 진지한 사람을 찾습니다.
        <br></br> 그래서 아래 조건을 갖춘 사람을 찾습니다.
      </MessageReceived>
      <MessageReceived>
        1. 지금 자신에게 어렵게 느껴지는 <b>분명한 문제가</b> 있는 사람을
        찾습니다.
        <br />
        <br />
        2. 피치 못할 사정이 없다면, 매달 열리는 모임에 빠지지 않을 사람을
        찾습니다. <br />
        <br />
        3. 모임 전까지 자신이 이루겠다고 말한 바를 지키려고 애쓸 사람을
        찾습니다.
      </MessageReceived>
      <MessageSent>주간 회고는 어디다 적나요?</MessageSent>
      <MessageReceived>노션에 작성할 예정입니다.</MessageReceived>
      <MessageSent>매달 어디서 만나나요?</MessageSent>
      <MessageReceived>
        버클리와 샌프란시스코에 근사한 <b>무진장 라운지</b>가 마련되어 있습니다.
      </MessageReceived>
      <MessageSent>만나서 뭘 하나요?</MessageSent>
      <MessageReceived>크게 세가지를 나누려고 합니다.</MessageReceived>
      <MessageReceived>
        1. 달성한 마일스톤에 대해서 축하받기. <br />
        <br />
        2. 지금 씨름하고 있는 문제에 대해 솔직하게 나누기. <br />
        <br />
        3. 문제 해결에 필요한 도움을 요청하기.
      </MessageReceived>
      <MessageReceived>
        사실 문제는 누구에게나 있죠. 무진장에는{" "}
        <b>자신의 문제에 대해서 우선 솔직하게 인정한</b> 사람들이 모였습니다.
        그러니 우리 두려움과 이고는 내려놓고 솔직하게 나눠봅시다. 어쩌면 자신의
        치부를 드러낼 정도로 솔직해 질 때, 더이상 감출게 없어지면서 타인과
        자신의 문제에 대해 함께 고민해볼 수 있는 것 같습니다. 그게 솔직함의
        힘이라고 믿습니다. <br /> <br /> 그 솔직함이 결국 해내는데 도움이
        될거라고 확신합니다.
      </MessageReceived>
      <MessageSent>해내지 못하면요?</MessageSent>
      <MessageReceived>
        무진장 애써봤는데도 해내지 못했다고요? 그럼 그 나름대로 얻은 값진 게
        분명히 있을라고 믿습니다.
      </MessageReceived>
    </Wrapper>
  );
};

const Wrapper = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const Message = styled.li`
  display: block;
  padding: 16px;
  margin: 8px;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
`;

const MessageSent = styled(Message)`
  background: ${COLORS.secondary};
  color: white;
`;

const MessageReceived = styled(Message)`
  background: hsl(0deg 0% 90%);
  color: black;
  align-self: flex-end;
`;

const Header = styled.b`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.5px;
  word-break: keep-all;
  text-align: center;
  border-bottom: solid black;
`;

export default Faq;
