import React from "react";
import styled from "styled-components/macro";

import { WEIGHTS } from "../../constants";

const Logo = (props) => {
  return (
    <Link href="/">
      <Wrapper {...props}>무진장(無盡藏)</Wrapper>
    </Link>
  );
};

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  flex: 1;
`;

const Wrapper = styled.h1`
  font-size: 1.5rem;
  font-weight: ${WEIGHTS.bold};
  min-width: 145px;
`;

export default Logo;
