import React from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components/macro";

import LandingPage from "../LandingPage";
import RedCliff from "../RedCliff";
import { COLORS } from "../../constants";

const Main = (props) => {
  const color = props.showRedCliff ? COLORS.gray[900] : "hsl(180deg, 25%, 92%)";

  return (
    <MainIndex color={color}>
      {props.showRedCliff && <RedCliff />}
      {!props.showRedCliff && (
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      )}
    </MainIndex>
  );
};

const MainIndex = styled.main`
  padding: 64px 32px;
  background-color: ${(props) => props.color};
`;

export default Main;
