import React from "react";

import styled from "styled-components/macro";

const RedCliff = () => {
  return (
    <Wrapper>
      <Heading>전적벽부(前赤壁賦)</Heading>
      <AuthorName>소식(蘇軾)</AuthorName>
      <Paragraph>
        1082년 가을 음력 7월 16일, 소동파 선생은 손님과 함께 적벽 아래 배를 띄워
        노닐었지. 맑은 바람 천천히 불어오고, 물결은 잔잔하더군. 술 들어 손님에게
        권하고, 시경 명월 시를 읊고, 요조의 장을 노래했네.{" "}
      </Paragraph>
      <Paragraph>
        조금 지나니 달이 동쪽 산 위로 오르고, 두성과 우성사이를 배회하더군. 하얀
        물안개는 강을 가로지르고, 물빛은 하늘에 가 닿는데, 갈잎 같은 작은 배를
        가는 대로 내버려두었더니 끝없이 펼쳐진 물결 위를 넘어갔네. 넓고도
        넓어라, 허공을 타고 바람을 몰아가 어디에 이를지 모르는 듯하여라.
        나부끼고 나부껴라, 세상을 저버리고 홀로 서서, 날개 돋친 신선 되어 하늘로
        오르는 듯하여라.{" "}
      </Paragraph>
      <Paragraph>
        바로 이때, 취기 어린 즐거움이 달아올라, 뱃전을 두드리며 노래했지.
        계수나무 노와 목란나무 상앗대로 물위에 비친 달그림자를 치고, 물결에 비친
        달빛을 헤치네. 막막하도다, 내 마음이여. 바라보네. 하늘 저쪽에 있을 고운
        님을. 퉁소 부는 손님이 노래에 맞추어 화답하는데. 그 소리가 구슬퍼,
        원망하는 듯, 사모하는 듯, 흐느끼는 듯, 애원하는 듯. 여운이 가늘게 이어져
        실처럼 끊이지 않으니, 어두운 골짜기의 교룡을 춤추게 하고, 외로운 배의
        과부를 흐느끼게 하리라.{" "}
      </Paragraph>
      <Paragraph>
        소동파 선생, 정색하고 옷깃을 여미며, 자세를 바로하여 손님에게 물었네.
        “어찌 소리가 그러한가?”{" "}
      </Paragraph>
      <Paragraph>
        손님이 말했다. “’달이 밝아 별이 희믜한데 까마귀와 까치가 남쪽으로
        날아가네.’ 이것은 조조의 시가 아닌가? 서쪽 하구를 바라보고 동쪽 무창을
        바라보니, 산천이 서로 얽혀 울창하고 푸르다. 이곳은 조조가 주유에게
        곤욕을 치뤘던 곳이 아닌가. 형주를 막 격파하고, 강릉으로 내려와 물결을
        따라 동쪽으로 진군할 때 배들은 천 리에 걸쳐 꼬리르 물고 깃발은 창공을
        덮었다. 술을 부어 강가에 나가 창을 비껴들고 시를 읊조릴 때, 조조는 정녕
        일세의 영웅이었지. 그런데 지금 그는 어디에 있는가? 하물며 그대와 나는
        강가에서 낚시하고 나무하며, 물고기와 새우와 짝하고 고라니와 사슴과
        벗하고, 일엽편주를 타고 술잔을 들어 서로에게 권하는 처지라네. 천지에
        하루살이가 깃들어 있는 것이요, 넓은 바다에 낟알 하나에 불과할 뿐. 우리
        인생이 잠깐임을 슬퍼하고 장강이 무구함을 부러워하네. 날아다니는 신선을
        끼고서 노닐며, 밝은 달을 품고서 길게 마치려 하지만 갑작스레 얻을 수
        없음을 알고서 슬픈바람(퉁소 소리)에 여운을 맡겨보았네.”{" "}
      </Paragraph>
      <Paragraph>
        소동파 선생이 말했다. “손님도 물과 달에 대해서 아시는가? 물의 경우 가는
        것은 이와 같지만, 일찍이 아예 가버린 적은 없고, 달의 경우 차고 기우는
        것이 저와 같지만 끝내 사라지거나 더 커진 적은 없다네. 무릇 변화의
        관점에서 보면, 천지가 한순간도 가만히 있은 적이 없고, 불변의 관점에서
        보자면 만물과 나는 모두 다함이 없으니, 달리 무엇을 부러워하리오? 무릇
        천지간의 사물은 각기 주인이 있소. 진정 나의 소유가 아니라면 터럭
        하나라도 취해서는 아니 되오. 오직 강 위의 맑은 바람과 산 사이의 밝은
        달은 귀가 취하면 소리가 되고, 눈이 마주하면 풍경이 되오. 그것들은
        취하여도 금함이 없고 써도 다함이 없소. 이것이야말로 조물주의{" "}
        <b>무진장(고갈되지 않는 창고)</b>이니, 나와 그대가 함께 즐길 바이외다.”{" "}
      </Paragraph>
      <Paragraph>
        손님이 기뻐하며 웃었고, 잔을 씻어 술을 다시 따랐지. 안주는 다 먹었고,
        술잔과 쟁반이 낭자하였더라. 배 안에 서로 베고 깔고 누워, 동쪽이 이미
        희부옇게 밝았음을 알지 못하였더라.
      </Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  column-count: 2;
  column-gap: 150px;
  max-width: 128rem;
  margin: 32px auto;
  border: 2px solid hsl(35deg 10% 40%);
  padding: 50px;
  background: linear-gradient(
    to right,
    hsl(35deg, 30%, 90%),
    hsl(35deg, 30%, 90%) 47%,
    hsl(35deg, 30%, 70%) 49.5%,
    hsl(35deg, 20%, 50%) 50%,
    hsl(35deg, 30%, 70%) 50.5%,
    hsl(35deg, 30%, 90%) 53%,
    hsl(35deg, 30%, 90%)
  );
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 2em;
`;

const Paragraph = styled.p`
  text-align: justify;

  &:not(:first-of-type) {
    text-indent: 2em;
    margin-top: 3em;
  }
`;

const AuthorName = styled.h1`
  text-align: right;
  margin-top: -3em;
  margin-bottom: 3em;
`;

export default RedCliff;
