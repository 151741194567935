import React from "react";

import Footer from "../Footer";
import Header from "../Header";
import Main from "../Main";
import { useState } from "react";

const App = () => {
  const [showRedCliff, setShowRedCliff] = useState(false);

  return (
    <>
      <Header setShowRedCliff={setShowRedCliff} showRedCliff={showRedCliff} />
      <Main showRedCliff={showRedCliff} />
      <Footer />
    </>
  );
};

export default App;
