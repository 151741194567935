import React from "react";
import styled from "styled-components/macro";

import { COLORS } from "../../constants";

const Footer = () => {
  // Our site features two visual headers, but they should be
  // grouped semantically as a single header.
  return (
    <footer>
      <Wrapper>
        <MarketingMessage>
          Copyright © 2024{" "}
          <Maker href="https://www.instagram.com/moojin.io//">
            Moojin Jang LLC.
          </Maker>{" "}
          All rights reserved.
        </MarketingMessage>
      </Wrapper>
    </footer>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  align-items: center;
  color: ${COLORS.gray[300]};
  background-color: ${COLORS.gray[900]};
  display: flex;
  min-height: 40px;
  padding-left: 32px;
  padding-right: 32px;
`;

const MarketingMessage = styled.span`
  color: ${COLORS.white};
`;

const Maker = styled.a`
  color: inherit;
`;

export default Footer;
